import { cssVar } from 'utils/css';

export const getCollapseOverrides = () => ({
  root: {
    '& .snackSuccess, & .snackInfo, & .snackError': {
      borderRadius: cssVar('--border-radius'),
      boxShadow: cssVar('--shadow-bar'),
      fontSize: '0.875rem',
      minWidth: '0',
      padding: '0',
      '&::before': {
        borderRadius: cssVar('--border-radius) 0 0 var(--border-radius'),
        bottom: '0',
        content: '""',
        left: '0',
        position: 'absolute',
        top: '0',
        width: '3.375rem',
      },
      '& > *': {
        flexGrow: '1',
        padding: '1rem 1rem 1rem 4.375rem',
        '& svg': {
          left: '1.0625rem',
          marginInlineEnd: '0 !important',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
        },
      },
    },
    '& [class*="snackSuccess"]': {
      backgroundColor: cssVar('--color-white'),
      color: cssVar('--color-mine-shaft'),
      '&::before': {
        backgroundColor: cssVar('--color-frost'),
      },
      '& svg': {
        color: cssVar('--color-pistachio'),
      },
    },
    '& [class*="snackInfo"]': {
      backgroundColor: cssVar('--color-white'),
      color: cssVar('--color-mine-shaft'),
      '&::before': {
        backgroundColor: cssVar('--color-polar'),
      },
      '& svg': {
        color: cssVar('--color-curious-blue'),
      },
    },
    '& [class*="snackError"]': {
      backgroundColor: cssVar('--color-white'),
      color: cssVar('--color-mine-shaft'),
      '&::before': {
        backgroundColor: cssVar('--color-pourri'),
      },
      '& svg': {
        color: cssVar('--color-milano-red'),
      },
    },
  },
});
