export const getInputAdornmentOverrides = () => ({
  root: {
    backgroundColor: 'transparent',
    border: '0',
    borderRadius: '0',
    height: 'auto',
    opacity: '0.5',
    padding: '0',
  },
  positionStart: {
    marginLeft: '0.5rem',
    marginRight: '1rem',
  },
  positionEnd: {
    marginLeft: '1rem',
    marginRight: '0',
  },
});
