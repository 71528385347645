import { Box, Card, CardContent } from '@mui/material';
import LoadingContainer from '../LoadingContainer';
import { loadingContainer } from 'theme/custom/loading-container';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useQueryParams } from 'component/hooks/queryParams';
import { useAcceptInvite } from 'api/auth';
import { useNavigate } from 'react-router-dom';

export const AcceptInvite = () => {
  const { siteId = '' } = useParams<{ siteId: string }>();
  const { t } = useTranslation();
  const params = useQueryParams();
  const token = params.get('token') ?? '';
  const { data, isSuccess } = useAcceptInvite(siteId, token);
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      navigate(`/sites/${siteId}/overview`);
    }
  }, [data, isSuccess, navigate]);

  return (
    <Card
      sx={{
        maxWidth: '80%',
        margin: '0 auto',
      }}
    >
      <CardContent>
        <Box sx={{ textAlign: 'center' }}>{t('accepting_invite')}</Box>
        <Box
          sx={{
            ...loadingContainer,
            display: 'flex',
            height: '21.875rem',
          }}
        >
          <LoadingContainer />
        </Box>
      </CardContent>
    </Card>
  );
};
