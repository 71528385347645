import { useCallback, useMemo } from 'react';
import { jwtDecode } from 'jwt-decode';
import Cookie from 'js-cookie';
import { RocketIdentity, Token } from 'api/types';
import { AUTH0_DOMAIN } from '../constants';
import { isInIframe } from './iframe';
import { useAuth0 } from '@auth0/auth0-react';
import { getIsWpSiteControl } from 'utils';

export function decodeToken(token: any): Token | null {
  if (!token) return null;
  try {
    return jwtDecode(String(token));
  } catch (e) {
    return null;
  }
}

export function useGetToken() {
  return useMemo(() => {
    const cookie = Cookie.get('user_session');
    return decodeToken(cookie) as Token;
  }, []);
}

/**
 * Backwards compatibility with older style JWTs
 * @param token
 */
export function isOldStyleToken(token: Token): boolean {
  return !(token?.identity && typeof token?.identity?.sub === 'string') && !isAuth0Token(token);
}

function isAuth0Token(token: Token): boolean {
  return token?.iss?.includes(AUTH0_DOMAIN) ?? false;
}

/**
 * If this is site access (Reseller User)
 * @param token
 */
export function isSiteAccess(token: Token): boolean {
  if (isOldStyleToken(token)) {
    if (token?.identity === 'site_access') {
      return true;
    }
  } else {
    const rocket_token = token?.identity as RocketIdentity;
    if (rocket_token?.rocket_claims?.roles?.includes('role:site')) {
      return true;
    }
  }

  return false;
}

export function isAccountUser(token: Token): boolean {
  if (isOldStyleToken(token)) {
    return false;
  } else {
    const rocket_token = token?.identity as RocketIdentity;
    if (rocket_token?.rocket_claims?.roles?.includes('role:account_user')) {
      return true;
    }
  }

  return false;
}

/**
 * Gets user's email / username from token
 * @param token
 */
export function getUsername(token: Token): string {
  if (isOldStyleToken(token)) {
    return token?.identity as string;
  } else {
    const rocket_token = token?.identity as RocketIdentity;
    if (rocket_token?.rocket_claims?.username) {
      return rocket_token?.rocket_claims?.username;
    }
    if (rocket_token?.rocket_claims?.login_email) {
      return rocket_token?.rocket_claims?.login_email;
    }
  }

  return '';
}

/**
 * If the user is the "site owner" (managed or reseller's token)
 *
 * @param token
 */
export function isManagedHosting(token: Token): boolean {
  if (isOldStyleToken(token)) {
    return (
      token?.user_claims?.account_type === 'managed' ||
      token?.user_claims?.account_type === undefined
    );
  }

  const rocket_token = token?.identity as RocketIdentity;
  return !!(
    rocket_token?.rocket_claims?.roles?.includes('role:user') &&
    rocket_token?.rocket_claims?.user_type === 'managed'
  );
}

export const getUserSession = () => {
  const userSession = isInIframe()
    ? window.sessionStorage.getItem('user_session')
    : Cookie.get('user_session');
  return userSession;
};

export const useLogout = () => {
  const auth0 = useAuth0();

  const logout = useCallback(() => {
    Cookie.remove('user_session');

    if (!isInIframe()) {
      const loginPath = getIsWpSiteControl() ? 'login/wpsitecontrol' : 'login';

      auth0.logout({
        logoutParams: {
          federated: true,
          returnTo: `${window.location.origin}/${loginPath}`,
        },
      });
    }
  }, [auth0]);

  return logout;
};
