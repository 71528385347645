import { cssVar } from 'utils/css';

export const getDialogTitleOverrides = () => ({
  root: {
    alignItems: 'center',
    backgroundColor: cssVar('--color-hint-of-red'),
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.25rem 1rem',
    '@media (min-width: 60rem)': {
      paddingLeft: '1.25rem',
      paddingRight: '1.25rem',
    },
    '&:not(:last-child)': {
      borderBottom: '0.0625rem solid var(--color-ebb)',
    },
    '& + *': {
      paddingTop: '1.25rem !important',
    },
    '& h6': {
      '& + *': {
        alignItems: 'center',
        display: 'flex',
        flex: '0 0 auto',
        '& strong': {
          color: cssVar('--color-mine-shaft'),
          fontWeight: 600,
          marginRight: '0.1875rem',
        },
      },
    },
    '& button': {
      backgroundColor: cssVar('--color-white'),
      border: '0.125rem solid var(--primary)',
      fontSize: '1rem',
      height: '2.75rem',
      margin: '-0.625rem -0.625rem -0.625rem 0.5rem',
      width: '2.75rem',
      '&:hover': {
        backgroundColor: cssVar('--color-white'),
        borderColor: cssVar('--primary-hover'),
      },
      '&:active': {
        backgroundColor: cssVar('--color-white'),
        borderColor: cssVar('--primary-active'),
      },
    },
  },
});
