import { cssVar } from 'utils/css';

export const getButtonOverrides = () => ({
  root: {
    borderRadius: '0',
    color: cssVar('--color-mako'),
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: '1.6',
    minWidth: '0',
    padding: '0',
    transition: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      transform: 'translateY(0.125rem)',
    },
    '&$disabled': {
      color: cssVar('--color-mako'),
      opacity: '0.5',
      pointerEvents: 'none',
    },
    '& div[class*="Circular"]': {
      margin: '0 -0.5625rem',
    },
    '& svg': {
      display: 'block',
    },
  },
  text: {
    padding: '0',
    '& svg': {
      fontSize: '0.875rem',
    },
    '& [role="progressbar"]': {
      color: 'inherit',
      height: '1rem !important',
      width: '1rem !important',
    },
  },
  textPrimary: {
    color: cssVar('--primary'),
    '&:hover': {
      backgroundColor: 'transparent',
      color: cssVar('--primary-hover'),
    },
    '&:active': {
      color: cssVar('--primary-active'),
    },
    '&$disabled': {
      color: cssVar('--primary'),
    },
  },
  contained: {
    backgroundColor: cssVar('--color-hint-of-red'),
    borderRadius: cssVar('--border-radius'),
    boxShadow: 'none',
    color: cssVar('--color-mako'),
    padding: '0.6875rem 1.25rem',
    '&:hover': {
      backgroundColor: cssVar('--color-ebb'),
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: cssVar('--color-wafer'),
      boxShadow: 'none',
    },
    '&$disabled': {
      backgroundColor: cssVar('--color-hint-of-red'),
      color: cssVar('--color-mako'),
    },
    '& [role="progressbar"]': {
      color: 'inherit',
      height: '1.375rem !important',
      width: '1.375rem !important',
    },
  },
  containedPrimary: {
    backgroundColor: cssVar('--primary'),
    color: cssVar('--color-white'),
    '&:hover': {
      backgroundColor: cssVar('--primary-hover'),
    },
    '&:active': {
      backgroundColor: cssVar('--primary-active'),
    },
    '&$disabled': {
      backgroundColor: cssVar('--primary'),
      color: cssVar('--color-white'),
    },
  },
  containedSecondary: {
    backgroundColor: cssVar('--color-milano-red'),
    color: cssVar('--color-white'),
    '&:hover': {
      backgroundColor: cssVar('--color-totem-pole'),
    },
    '&:active': {
      backgroundColor: cssVar('--color-dark-burgundy'),
    },
    '&$disabled': {
      backgroundColor: cssVar('--color-milano-red'),
      color: cssVar('--color-white'),
    },
  },
  outlined: {
    border: '0.125rem solid var(--color-hint-of-red)',
    borderRadius: cssVar('--border-radius'),
    padding: '0.5625rem 1.125rem',
    '&:hover': {
      border: '0.125rem solid var(--color-ebb)',
    },
    '&:active': {
      border: '0.125rem solid var(--color-wafer)',
    },
    '&$disabled': {
      border: '0.125rem solid var(--color-hint-of-red)',
    },
    '& [role="progressbar"]': {
      color: 'inherit',
      height: '1.375rem !important',
      width: '1.375rem !important',
    },
  },
  outlinedPrimary: {
    border: '0.125rem solid var(--primary)',
    color: cssVar('--primary'),
    '&:hover': {
      backgroundColor: 'transparent',
      border: '0.125rem solid var(--primary-hover)',
      color: cssVar('--primary-hover'),
    },
    '&:active': {
      border: '0.125rem solid var(--primary-active)',
      color: cssVar('--primary-active'),
    },
    '&$disabled': {
      border: '0.125rem solid var(--primary)',
      color: cssVar('--primary'),
    },
  },
  outlinedSecondary: {
    border: '0.125rem solid var(--color-milano-red)',
    color: cssVar('--color-milano-red'),
    '&:hover': {
      backgroundColor: 'transparent',
      border: '0.125rem solid var(--color-totem-pole)',
      color: cssVar('--color-totem-pole'),
    },
    '&:active': {
      border: '0.125rem solid var(--color-dark-burgundy)',
      color: cssVar('--color-dark-burgundy'),
    },
    '&$disabled': {
      border: '0.125rem solid var(--color-milano-red)',
      color: cssVar('--color-milano-red'),
    },
  },
  iconSizeMedium: {
    '& > *:first-of-type': {
      fontSize: 'unset',
    },
  },
  startIcon: {
    fontSize: '1rem',
    marginLeft: '0',
    marginRight: '0.3125rem',
  },
  endIcon: {
    fontSize: '1rem',
    marginLeft: '0.3125rem',
    marginRight: '0',
  },
});
