import { cssVar } from 'utils/css';

export const getIconButtonOverrides = () => ({
  root: {
    color: cssVar('--color-mako'),
    fontSize: '0.875rem',
    padding: '0.5625rem',
    transition: 'none',
    '& svg': {
      height: '1.125rem',
      width: '1.125rem',
    },
  },
  colorPrimary: {
    color: cssVar('--primary'),
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      color: cssVar('--primary-hover'),
    },
    '&:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      color: cssVar('--primary-active'),
    },
  },
});
