import { datadogRum } from '@datadog/browser-rum';

if (import.meta.env.PROD) {
  datadogRum.init({
    applicationId: '8c4f0e98-9781-458f-b47e-8db3c6a405c3',
    clientToken: 'puba5eab3b4ba0bad38d0d6fc7f558943ea',
    site: 'datadoghq.com',
    service: 'portal-ui',
    env: import.meta.env.MODE,
    version: import.meta.env.VITE_APP_VERSION,
    sessionSampleRate: 10,
    sessionReplaySampleRate: 10,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    beforeSend: event => {
      if (event.type === 'error' && event.error.type === 'AxiosError') {
        return false;
      }
      return true;
    },
  });

  datadogRum.startSessionReplayRecording();
}
