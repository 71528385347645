import { cssVar } from 'utils/css';

export const getTabsOverrides = () => ({
  root: {
    minHeight: '0',
    '&:not(:first-of-type)': {
      marginTop: '1.25rem',
    },
    '&:not(:last-child)': {
      borderBottom: '0.0625rem solid var(--color-ebb)',
      marginBottom: '1.25rem',
    },
  },
  indicator: {
    backgroundColor: cssVar('--primary'),
    height: '0.1875rem',
  },
});
