import { useMemo } from 'react';
import * as ReactQuery from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { linkHelper } from 'linkHelper';
import { isInIframe } from 'utils/iframe';
import { delay } from 'utils/delay';

const whitelistedMutations = [/^sites\/users\/login/, /^login/, /^sites\/users\/.+\/password/];

export default function QueryClientProvider({ children }: { readonly children: React.ReactNode }) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  /**
   * Handles 401 errors from backend by having the user log in again
   * @param error
   */
  const getQueryClient = useMemo(() => {
    const handleResponseError = async function (error: unknown) {
      if (error instanceof AxiosError) {
        const url = error.config?.url || '';
        const is401Error = error.response?.status == 401;
        const matchesWhitelistedMutation = whitelistedMutations.some(regExp => regExp.test(url));

        if (!url || !is401Error || matchesWhitelistedMutation) {
          return;
        }

        enqueueSnackbar(t('authenticationError'), {
          key: 'authorizationError',
          preventDuplicate: true,
          variant: 'error',
          persist: true,
        });

        if (!isInIframe()) {
          await delay(4000);
          window.location.href = linkHelper.logout.getLink();
        }
      }
    };

    return new ReactQuery.QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
        },
        mutations: {
          onError: error => {
            handleResponseError(error);
          },
        },
      },
      queryCache: new ReactQuery.QueryCache({
        onError: error => {
          handleResponseError(error);
        },
      }),
    });
  }, []);

  return (
    <ReactQuery.QueryClientProvider client={getQueryClient}>
      {children}
    </ReactQuery.QueryClientProvider>
  );
}
