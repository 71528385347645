export const loadingContainer = {
  '&': {
    minHeight: '21.875rem',
    position: 'relative',
    '&:not(:first-of-type)': {
      marginTop: '1.25rem',
    },
    '&:not(:last-child)': {
      marginBottom: '1.25rem',
    },
  },
};
