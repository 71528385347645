import { Box, Button, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { PropsWithChildren, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

const ErrorPage = () => {
  const handleReload = window.location.reload;

  return (
    <Box sx={{ height: '100vh', width: '100vw', display: 'grid', placeItems: 'center' }}>
      <Stack gap={6}>
        <Typography variant="h1">Something went wrong</Typography>
        <Button variant="contained" color="primary" onClick={handleReload}>
          Reload
        </Button>
      </Stack>
    </Box>
  );
};

export const ErrorHandler = ({ children }: PropsWithChildren) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const handleUnhandledRejection = (event: PromiseRejectionEvent) => {
      const defaultMessage = undefined; // Use enqueueSnackbar default message
      const serverMessage = event.reason?.response?.data?.messages?.[0];

      enqueueSnackbar(serverMessage || defaultMessage, { variant: 'error' });
    };

    window.addEventListener('unhandledrejection', handleUnhandledRejection);

    return () => {
      window.removeEventListener('unhandledrejection', handleUnhandledRejection);
    };
  }, []);

  return <ErrorBoundary FallbackComponent={ErrorPage}>{children}</ErrorBoundary>;
};
