import { cssVar } from 'utils/css';

export const getOutlinedInputOverrides = () => ({
  root: {
    borderRadius: cssVar('--border-radius'),
    '&:hover $notchedOutline': {
      borderColor: cssVar('--color-silver-sand'),
    },
    '&$focused $notchedOutline': {
      borderColor: cssVar('--primary'),
      borderWidth: '0.0625rem',
    },
  },
  input: {
    padding: '0.6875rem 1rem',
  },
  multiline: {
    padding: '0',
  },
  notchedOutline: {
    borderColor: cssVar('--color-silver-sand'),
    borderWidth: '0.0625rem !important',
  },
  adornedStart: {
    paddingLeft: '0.5rem',
  },
  adornedEnd: {
    paddingRight: '0.5rem',
  },
});
