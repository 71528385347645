import { cssVar } from 'utils/css';

export const getSelectOverrides = () => ({
  select: {
    alignItems: 'center',
    display: 'flex',
    minWidth: '0',
    '& > svg': {
      fontSize: '1.25rem',
      marginRight: '0.3125rem',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectMenu: {
    minHeight: '0',
  },
  outlined: {
    borderRadius: cssVar('--border-radius'),
    '&$outlined': {
      paddingRight: '2.5rem',
    },
  },
  iconOutlined: {
    color: cssVar('--color-mako'),
    right: '0.5rem',
    top: '50%',
    transform: 'translateY(-50%)',
  },
});
