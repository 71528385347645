import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from 'locales/en.json';
import deTranslations from 'locales/de.json';
import esTranslations from 'locales/es.json';
import frTranslations from 'locales/fr.json';

const browserLng = navigator.language.split('-')[0];

export const initI18next = (lng: string = browserLng) => {
  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: {
          ...enTranslations,
        },
      },
      de: {
        translation: {
          ...deTranslations,
        },
      },
      es: {
        translation: {
          ...esTranslations,
        },
      },
      fr: {
        translation: {
          ...frTranslations,
        },
      },
    },
    fallbackLng: 'en',
    lng,
    interpolation: {
      escapeValue: false,
    },
  });
};
