import { cssVar } from 'utils/css';

export const getListItemOverrides = () => ({
  root: {
    paddingBottom: '0.625rem',
    paddingTop: '0.625rem',
    '&:not(:last-child)': {
      borderBottom: '0.0625rem solid var(--color-hint-of-red)',
    },
    '&$selected': {
      backgroundColor: 'transparent',
      color: cssVar('--primary'),
      '&:hover': {
        backgroundColor: cssVar('--primary-menu-hover'),
      },
      '&:active': {
        backgroundColor: cssVar('--primary-menu-active'),
      },
    },
    '&$focusVisible': {
      backgroundColor: cssVar('--primary-menu-hover'),
    },
    '& > svg': {
      color: cssVar('--primary'),
    },
    '& > svg:first-of-type': {
      marginRight: '0.3125rem',
    },
    '& .flag': {
      display: 'inline-flex',
      marginRight: '0.3125rem',
      marginTop: '-0.125rem',
      verticalAlign: 'middle',
      '& img': {
        display: 'block',
      },
    },
  },
  gutters: {
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
  },
});
