import { cssVar } from 'utils/css';

export const getPaperOverrides = () => ({
  root: {
    color: cssVar('--color-mako'),
    transition: 'none',
  },
  rounded: {
    borderRadius: cssVar('--border-radius'),
  },
  elevation1: {
    border: '0.0625rem solid var(--color-ebb)',
    boxShadow: 'none',
  },
  elevation4: {
    boxShadow: cssVar('--shadow-bar'),
  },
  elevation8: {
    boxShadow: cssVar('--shadow-bar'),
  },
  elevation24: {
    boxShadow: cssVar('--shadow-bar'),
  },
});
