import { cssVar } from 'utils/css';

export const getCardOverrides = () => ({
  root: {
    '&:only-child': {
      minHeight: '100%',
    },
    '&:not(:first-of-type)': {
      marginTop: '1.5rem',
    },
    '&:not(:last-child)': {
      marginBottom: '1.5rem',
    },
    '& strong': {
      color: cssVar('--color-mine-shaft'),
      fontWeight: 600,
    },
  },
});
