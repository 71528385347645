import axios from 'axios';
import Cookie from 'js-cookie';
import qs from 'query-string';
import { API_URL, MOCK_API_URL } from '../constants';

export const AxiosService = axios.create({
  baseURL: `${API_URL}/v1/`,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const AxiosMockService = axios.create({
  baseURL: `${MOCK_API_URL}/`,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const AxiosFileService = axios.create({
  baseURL: `${API_URL}/v1/`,
  headers: {
    Accept: 'application/gzip',
  },
});

export const AxiosInternalService = axios.create({
  baseURL: `${API_URL}/internal/v1/`,
  headers: {
    'Content-Type': 'application/json',
  },
});

/**
 * Updates Authorization header Bearer token
 * @param token The JWT base64 value
 */

const qsParams = qs.parse(window.location.search);

let jwt = Cookie.get('user_session');

if (typeof qsParams.token === 'string') {
  jwt = qsParams.token;
}

export function setAuthorizationHeaders(jwt: string) {
  AxiosService.defaults.headers.common.Authorization = `Bearer ${jwt as string}`;
  AxiosFileService.defaults.headers.common.Authorization = `Bearer ${jwt as string}`;
  AxiosInternalService.defaults.headers.common.Authorization = `Bearer ${jwt as string}`;
}

setAuthorizationHeaders(jwt as string);
