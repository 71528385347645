export const getCardHeaderOverrides = () => ({
  root: {
    borderBottom: '0.0625rem solid var(--color-ebb)',
    padding: '1.25rem 1rem',
    '&': {
      flexWrap: 'wrap',
    },
  },
  title: {
    fontSize: '1.125rem',
    '& > [class*="Tabs"]': {
      marginBottom: '0.5rem',
      marginTop: '-1.25rem',
      '@media (min-width: 37.5rem)': {
        marginBottom: '-1.25rem',
      },
      '& button': {
        paddingTop: '1.25rem',
        '@media (min-width: 37.5rem)': {
          paddingBottom: '1.3125rem',
        },
      },
    },
  },
  content: {
    '& > h2': {
      '& > span': {
        fontSize: '0.875rem',
        fontWeight: 400,
        opacity: '0.5',
      },
    },
  },
  action: {
    flex: '0 0 100%',
    marginBottom: '0',
    marginTop: '0.625rem',
    '@media (min-width: 37.5rem)': {
      flex: '0 0 auto',
      marginBottom: '-0.6563rem',
      marginTop: '-0.6563rem',
    },
  },
});
