import { cssVar } from 'utils/css';

export const getLinkOverrides = () => ({
  root: {
    color: cssVar('--primary'),
    fontWeight: 600,
    '&:hover': {
      color: cssVar('--primary-hover'),
    },
    '&:active': {
      color: cssVar('--primary-active'),
    },
    '& svg': {
      fontSize: '0.75rem',
      marginRight: '0.5rem',
    },
    cursor: 'pointer',
  },
  underlineHover: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
});
