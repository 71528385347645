import { cssVar } from 'utils/css';

export const getMenuItemOverrides = () => ({
  root: {
    color: cssVar('--color-mine-shaft'),
    fontSize: '0.875rem',
    minHeight: '3rem !important',
    paddingBottom: '0.625rem',
    paddingTop: '0.625rem',
    '&:not(:last-child)': {
      borderBottom: '0.0625rem solid var(--color-hint-of-red)',
    },
    '&:hover': {
      backgroundColor: cssVar('--primary-menu-hover'),
    },
    '&:active': {
      backgroundColor: cssVar('--primary-menu-active'),
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
  gutters: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
});
