import React from 'react';
import { createRoot } from 'react-dom/client';
import './theme/base/variables.css';
import './theme/base/reset.css';
import './theme/base/checkbox.css';
import App from './component/root/App';
import reportWebVitals from './reportWebVitals';
import { CssBaseline, ThemeProvider, StyledEngineProvider } from '@mui/material';
import { PostMessageProvider } from 'component/postMessage/PostMessageProvider';
import { SnackbarProvider } from 'notistack';
import 'datadog';
import { Auth0Provider } from '@auth0/auth0-react';
import { AUTH0_DOMAIN, AUTH0_CLIENT_ID } from './constants';
import QueryClientProvider from 'component/root/QueryClientProvider';
import { ErrorHandler } from 'component/root/ErrorHandler';

const container = document.getElementById('root');
const root = createRoot(container!);

async function enableMocking() {
  // disabling MSW since no current mocked apis
  // if (process.env.NODE_ENV !== 'development') {
  //   return;
  // }
  if (import.meta.env.MODE) {
    return;
  }

  const srcFilesRegEx = /\/src\/*/;
  const nodeModulesRegEx = /\/node_modules\/*/;
  const apiRegEx = /https:\/\/api\.rocket\.net\/*/;
  const rocketLogoRegEx = /\/rocket\.svg/;
  const rocketNetRegEx = /https:\/\/rocket\.net/;
  const googleFontRegEx = /https:\/\/fonts\.gstatic\.com/;

  const { worker } = await import('./mocks/browser');

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start({
    // turn off MSW warnings for specific routes
    onUnhandledRequest(req, print) {
      // specify routes to exclude
      const excludedRoutes = [
        srcFilesRegEx,
        nodeModulesRegEx,
        apiRegEx,
        rocketLogoRegEx,
        rocketNetRegEx,
        googleFontRegEx,
      ];

      // check if the req.url.pathname contains excludedRoutes
      const isExcluded = excludedRoutes.some(route => route.test(req.url));

      if (isExcluded) {
        return;
      }

      print.warning();
    },
  });
}

enableMocking().then(() => {
  root.render(
    <React.StrictMode>
      <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: `${window.location.origin}/post-login`,
        }}
        cacheLocation="localstorage"
        useRefreshTokens
        useRefreshTokensFallback
      >
        <PostMessageProvider>
          {({ theme }) => {
            return (
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <SnackbarProvider
                    maxSnack={1}
                    classes={{
                      variantSuccess: 'snackSuccess',
                      variantInfo: 'snackInfo',
                      variantError: 'snackError',
                    }}
                  >
                    <ErrorHandler>
                      <QueryClientProvider>
                        <CssBaseline />
                        <App />
                      </QueryClientProvider>
                    </ErrorHandler>
                  </SnackbarProvider>
                </ThemeProvider>
              </StyledEngineProvider>
            );
          }}
        </PostMessageProvider>
      </Auth0Provider>
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
