import { cssVar } from 'utils/css';

export const getLinearProgressOverrides = () => ({
  root: {
    borderRadius: '0.125rem',
    height: '0.625rem',
    minWidth: '6.25rem',
  },
  colorPrimary: {
    backgroundColor: cssVar('--color-hint-of-red'),
  },
  barColorPrimary: {
    backgroundColor: cssVar('--primary'),
  },
});
