const getRequiredEnv = (name: string) => {
  if (import.meta.env[name]) {
    return import.meta.env[name];
  }
  throw Error(`Environment variable: ${name} not found`);
};

const getRequiredEnvBool = (name: string) => {
  if (import.meta.env[name]) {
    return import.meta.env[name] === 'true';
  }
  throw Error(`Environment variable: ${name} not found`);
};

export const API_URL = getRequiredEnv('VITE_API_URL');
export const MOCK_API_URL = `https://control.local:3000/mock-api`;
export const SHOW_TICKETS = getRequiredEnvBool('VITE_SHOW_TICKETS');
export const SHOW_NEW_DESIGN = getRequiredEnvBool('VITE_SHOW_NEW_DESIGN');
export const AUTH0_DOMAIN = getRequiredEnv('VITE_AUTH0_DOMAIN');
export const AUTH0_CLIENT_ID = getRequiredEnv('VITE_AUTH0_CLIENT_ID');
export const WP_SITE_CONTROL_URL = getRequiredEnv('VITE_WP_SITE_CONTROL_URL');
export const UI_URL = getRequiredEnv('VITE_UI_URL');
export const COLOR_MODE_KEY = 'rocket-mode';
