export const getPopoverOverrides = () => ({
  paper: {
    maxHeight: 'none',
    maxWidth: '21.875rem',
    minHeight: '0',
    minWidth: '0',
    padding: '1.25rem 1rem',
    '& h3': {
      paddingBottom: '0.5rem',
    },
  },
});
