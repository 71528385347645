export const getDialogActionsOverrides = () => ({
  root: {
    justifyContent: 'flex-start',
    padding: '0 1rem 1.25rem 1rem',
    '@media (min-width: 60rem)': {
      paddingLeft: '1.25rem',
      paddingRight: '1.25rem',
    },
  },
  spacing: {
    '& > *:not(:first-of-type)': {
      marginLeft: '1rem',
    },
  },
});
