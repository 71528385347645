import { cssVar } from 'utils/css';

export const getAppBarOverrides = () => ({
  root: {
    zIndex: 1299,
  },
  colorPrimary: {
    backgroundColor: cssVar('--color-white'),
    color: cssVar('--color-mako'),
  },
});
