import { createContext } from 'react';

export interface SDK {
  token: string;
  siteId: string;
  header: 'show' | 'hide';
  logo?: string;
  colors?: {
    bodyBackground?: string;
    modalBackground?: string;
    iconPrimary?: string;
    iconSecondary?: string;
    primary?: string;
    primaryHover?: string;
    primaryActive?: string;
    primaryMenuHover?: string;
    primaryMenuActive?: string;
  };
  elementId?: string;
  language?: string;
  wpCliCustomDescription: string;
}

interface ContextProps {
  _sdk: SDK | null;
  getSdk: () => SDK | null;
  setSdk: (sdk: SDK) => void;
  sendMessage: (value: string) => void;
}

export const PostMessageContext = createContext<ContextProps>({
  _sdk: null,
  getSdk: () => null,
  setSdk: () => {},
  sendMessage: () => {},
});
