import { useCallback, useEffect } from 'react';
import { usePersistedState } from './usePersistedState';
import { useNavigate } from 'react-router-dom';
import { linkHelper } from 'linkHelper';
import { SHOW_NEW_DESIGN } from '../../constants';

export const useNewDesignSwitch = (defaultState: boolean) => {
  const [newDesign, setNewDesign] = usePersistedState(defaultState, 'new-design');
  const navigate = useNavigate();

  const oldDesignPath = linkHelper.dashboard.getLink();
  const newDesignPath = linkHelper.newDesign.dashboard.getLink();

  const isCurrentPathNewDesign = window.location.pathname.startsWith('/n/');

  const toggleNewDesign = useCallback(() => {
    setNewDesign(value => !value);
  }, []);

  useEffect(() => {
    const shouldUseNewDesign = SHOW_NEW_DESIGN && newDesign;

    if (shouldUseNewDesign && !isCurrentPathNewDesign) {
      navigate(newDesignPath);
    } else if (!shouldUseNewDesign && isCurrentPathNewDesign) {
      navigate(oldDesignPath);
    }
  }, [newDesign, navigate, newDesignPath, oldDesignPath, setNewDesign]);

  return { toggleNewDesign };
};
