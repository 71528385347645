import { cssVar } from 'utils/css';

export const getInputBaseOverrides = () => ({
  root: {
    color: cssVar('--color-mako'),
    fontSize: '0.875rem',
    lineHeight: '1.6',
  },
  input: {
    height: 'auto',
    padding: '0.6875rem 1rem',
  },
  adornedEnd: {
    '& input + button': {
      color: cssVar('--color-mako'),
      cursor: 'pointer',
      '&:hover': {
        color: cssVar('--primary-hover'),
      },
      '&:active': {
        color: cssVar('--primary-active'),
      },
    },
  },
});
