import { cssVar } from 'utils/css';

export const getFormLabelOverrides = () => ({
  root: {
    color: cssVar('--color-mako'),
    fontSize: '0.875rem',
    lineHeight: '1.6',
    '&$focused': {
      color: cssVar('--primary'),
    },
  },
});
