import { cssVar } from 'utils/css';

export const getRadioOverrides = () => ({
  root: {
    color: cssVar('--primary'),
    fontSize: '1.25rem',
    height: '1.25rem',
    padding: '0',
    width: '1.25rem',
    '& > span': {
      height: '100%',
      '& > div': {
        height: '100%',
        width: '100%',
      },
    },
    '& svg': {
      display: 'block',
      height: '1.25rem',
      width: '1.25rem',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  colorPrimary: {
    '&$checked': {
      color: cssVar('--primary'),
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
});
