export const getToolbarOverrides = () => ({
  regular: {
    minHeight: '3.75rem !important',
    '@media (min-width: 60rem)': {
      minHeight: '4.375rem !important',
    },
  },
  gutters: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  },
});
