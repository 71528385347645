import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
const PREFIX = 'LoadingContainer';

const classes = {
  loadingContainer: `${PREFIX}LoadingContainer`,
};

const StyledBox = styled(Box)({
  [`&.${classes.loadingContainer}`]: {
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    bottom: '0',
    display: 'flex',
    justifyContent: 'center',
    left: '0',
    position: 'absolute',
    right: '0',
    top: '0',
    zIndex: 100,
  },
});

const LoadingContainer = () => {
  return (
    <StyledBox className={classes.loadingContainer}>
      <CircularProgress />
    </StyledBox>
  );
};

export default LoadingContainer;
