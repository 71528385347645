import { cssVar } from 'utils/css';

export const getBaselineOverrides = () => ({
  '@global': {
    body: {
      backgroundColor: cssVar('--body-background'),
      color: cssVar('--color-mako'),
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.6',
      minWidth: '20rem',
      '& > .SnackbarContent-root': {
        '#notistack-snackbar': {
          paddingLeft: '60px',
        },
      },
    },
  },
});
