import { useEffect, useState, useMemo } from 'react';
import { PostMessageContext, SDK } from './PostMessageContext';
import { isInIframe } from 'utils/iframe';
import { initI18next } from 'i18n';
import { onCreateTheme } from '../../theme';
import { Theme } from '@mui/material/styles';

interface Props {
  readonly children: (props: { theme: Theme }) => React.ReactNode;
}

export function PostMessageProvider({ children }: Props) {
  const [sdk, setSdk] = useState<SDK | null>(null);
  const [i18NInitialized, setI18NInitialized] = useState(false);
  const isIframe = isInIframe();
  let url = document.location.href;

  if (isIframe) {
    try {
      url = document.referrer;

      if (!url) {
        url = window.location.ancestorOrigins[0];
      }
    } catch (e) {
      // not chrome
    }
  }

  useEffect(() => {
    if (!isIframe) {
      initI18next();
      setI18NInitialized(true);
    }
  }, [isIframe]);

  useEffect(() => {
    if (sdk && isIframe) {
      initI18next(sdk.language);
      setI18NInitialized(true);
    }
  }, [sdk, isIframe]);

  const setSdkAndStyles = (newSdk: SDK) => {
    setSdk(newSdk);

    if (newSdk?.colors !== undefined) {
      const stylesArray: string[] = [];

      if (newSdk.colors.iconPrimary) {
        stylesArray.push(`--icon-primary: ${newSdk.colors.iconPrimary};`);
      }
      if (newSdk.colors.iconSecondary) {
        stylesArray.push(`--icon-secondary: ${newSdk.colors.iconSecondary};`);
      }
      if (newSdk.colors.primary) {
        stylesArray.push(`--primary: ${newSdk.colors.primary};`);
      }
      if (newSdk.colors.primaryActive) {
        stylesArray.push(`--primary-active: ${newSdk.colors.primaryActive};`);
      }
      if (newSdk.colors.primaryHover) {
        stylesArray.push(`--primary-hover: ${newSdk.colors.primaryHover};`);
      }
      if (newSdk.colors.primaryMenuActive) {
        stylesArray.push(`--primary-menu-hover: ${newSdk.colors.primaryMenuActive};`);
      }
      if (newSdk.colors.primaryMenuHover) {
        stylesArray.push(`--primary-menu-active: ${newSdk.colors.primaryMenuHover};`);
      }
      if (newSdk.colors.bodyBackground) {
        stylesArray.push(`--body-background: ${newSdk.colors.bodyBackground};`);
      }

      if (newSdk.colors.modalBackground) {
        stylesArray.push(`--modal-background: ${newSdk.colors.modalBackground};`);
      }

      const sc = document.createElement('style');
      sc.style.setProperty('--body-background', 'what');
      const content = `:root { ${stylesArray.join(' ')} }`;

      sc.appendChild(document.createTextNode(content));

      document.head.appendChild(sc);
    }
  };

  useEffect(() => {
    const eventListener = (event: MessageEvent) => {
      if (url.startsWith(event.origin) && event.isTrusted) {
        const eventData = JSON.parse(event.data);

        switch (eventData.type) {
          case 'sendSdk':
            typeof eventData.data?.sdk === 'object' && setSdkAndStyles(eventData.data?.sdk as SDK);
            break;
          default:
            break;
        }
      }
    };

    const root = window.document.getElementById('root');

    const resizeListener = () => {
      const h = root?.offsetHeight;

      if (h) {
        window.parent.postMessage(
          JSON.stringify({
            type: 'resize',
            data: {
              height: h,
            },
          }),
          url
        );
      }
    };

    if (window.location !== window.parent.location) {
      window.addEventListener('message', eventListener);
      root?.addEventListener('resize', resizeListener);
      const body = window.document.getElementsByTagName('body');
      try {
        body[0].style.overflowY = 'hidden';
      } catch (e) {
        // should not have an error here
      }
      // send ready event
      window.parent.postMessage(JSON.stringify({ type: 'ready' }), url);

      const interval = setInterval(() => {
        const h = root?.offsetHeight;

        if (h !== 0) {
          clearInterval(interval);
          resizeListener();
        }
      }, 200);

      // create resize observer that is going to react to changes to the root element
      try {
        const resizeObserver = new ResizeObserver(entries => {
          let height = 0;
          if (entries[0].contentBoxSize && entries[0].contentBoxSize[0]) {
            height = entries[0].contentBoxSize[0]?.blockSize;
          } else {
            height = entries[0].contentRect.height;
          }

          window.parent.postMessage(
            JSON.stringify({
              type: 'resize',
              data: {
                height,
              },
            }),
            url
          );
        });

        root && resizeObserver.observe(root);
      } catch (e) {
        console.error('error initializing resize observer: ', e);
      }
    }

    return () => {
      try {
        window.removeEventListener('message', eventListener);
        root?.removeEventListener('resize', resizeListener);
      } catch (e) {
        // error
      }
    };
  }, []);

  const value = useMemo(
    () => ({
      _sdk: sdk,
      getSdk: () => sdk,
      setSdk: (sdk: SDK) => setSdk(sdk),
      sendMessage: (value: string) => {
        window.parent.postMessage(value, url);
      },
    }),
    [sdk]
  );

  if (!i18NInitialized) {
    return null;
  }

  if (!isIframe) {
    return <>{children({ theme: onCreateTheme() })}</>;
  }

  return (
    <PostMessageContext.Provider value={value}>
      {sdk ? <>{children({ theme: onCreateTheme() })}</> : null}
    </PostMessageContext.Provider>
  );
}
