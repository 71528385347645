import { cssVar } from 'utils/css';

export const getDrawerOverrides = () => ({
  root: {
    '@media (min-width: 56.25rem)': {
      display: 'none',
    },
    '& [class*="Backdrop"]': {
      backgroundColor: cssVar('--color-white'),
    },
    '& [class*="MuiListItem-root"]': {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
    '& [class*="MuiTypography-root"]': {
      fontWeight: 600,
    },
    '& [class*="MuiCollapse-root"]': {
      backgroundColor: cssVar('--color-hint-of-red'),
      borderColor: cssVar('--color-ebb'),
      borderStyle: 'solid',
      borderWidth: '0 0.3125rem 0.125rem 0.3125rem',
      '& [class*="MuiListItem-root"]': {
        paddingLeft: '1.3125rem',
        '&:not(:last-child)': {
          borderBottomColor: cssVar('--color-ebb'),
        },
      },
    },
  },
  paperAnchorTop: {
    top: '3.75rem',
  },
});
