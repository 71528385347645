import { cssVar } from 'utils/css';

export const getCheckboxOverrides = () => ({
  root: {
    color: cssVar('--primary'),
    fontSize: '1.25rem',
    height: '1.25rem',
    padding: '0',
    width: '1.25rem',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  colorPrimary: {
    '&$checked': {
      color: cssVar('--primary'),
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
});

export const getCheckboxSwitchOverrides = () => ({
  root: {
    height: '1.625rem',
    padding: '0',
    width: '3.375rem',
  },
  switchBase: {
    color: 'var(--color-white) !important',
    padding: '0.1875rem',
    '&.Mui-checked': {
      transform: 'translateX(1.75rem)',
      '& input': {
        left: '-1.75rem',
      },
      '& + .MuiSwitch-track': {
        '&::after': {
          content: '"I"',
          fontSize: '0.875rem',
          left: '0.375rem',
          right: 'auto',
          top: '50%',
          transform: 'translateY(-50%)',
        },
      },
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  colorPrimary: {
    '&.Mui-checked': {
      color: cssVar('--color-white'),
      '& + .MuiSwitch-track': {
        backgroundColor: 'var(--color-pistachio) !important',
        opacity: '1',
      },
    },
  },
  input: {
    height: '1.625rem',
    left: '0',
    width: '3.375rem',
  },
  thumb: {
    boxShadow: '0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15)',
    height: '1.25rem',
    width: '1.25rem',
  },
  track: {
    backgroundColor: cssVar('--color-wafer'),
    borderRadius: '1.625rem',
    opacity: '1',
    '&::after': {
      color: 'var(--color-white) !important',
      content: '"o"',
      fontSize: '1.125rem',
      fontWeight: 600,
      position: 'absolute',
      right: '0.375rem',
      textAlign: 'center',
      top: '-0.125rem',
      width: '1.5625rem',
    },
  },
});
