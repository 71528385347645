import { cssVar } from 'utils/css';

export const getTableRowOverrides = () => ({
  root: {
    '&.is-active > td': {
      backgroundColor: cssVar('--color-hint-of-red-active'),
      borderBottomColor: cssVar('--color-hint-of-red-active'),
    },
    '&.is-collapse > td': {
      backgroundColor: cssVar('--color-hint-of-red-collapse'),
      borderBottom: '0',
      padding: '0',
      '& > *': {
        borderBottomColor: cssVar('--color-ebb'),
        borderLeftColor: cssVar('--color-hint-of-red-active'),
        borderRightColor: cssVar('--color-hint-of-red-active'),
        borderStyle: 'solid',
        borderWidth: '0 0.3125rem 0.0625rem 0.3125rem',
        '& > *': {
          padding: '1rem',
        },
      },
    },
    '&:hover': {
      '& td': {
        backgroundColor: cssVar('--color-hint-of-red'),
      },
    },
  },
});
