export const getTableContainerOverrides = () => ({
  root: {
    '&:not(:first-of-type)': {
      marginTop: '1.25rem',
    },
    '&:not(:last-child)': {
      marginBottom: '1.25rem',
    },
  },
});
