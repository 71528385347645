import { Dispatch, SetStateAction, useEffect, useState } from 'react';

type StorageKey = 'new-design';

export const usePersistedState = <T>(
  value: T,
  key: StorageKey
): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState<T>(() => {
    try {
      const persistedState = localStorage.getItem(key);
      return JSON.parse(persistedState || '') ?? value;
    } catch {
      return value;
    }
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};
