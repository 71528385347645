import { cssVar } from 'utils/css';

export const getTableCellOverrides = () => ({
  root: {
    borderBottom: '0.0625rem solid var(--color-hint-of-red)',
    fontSize: '0.875rem',
    padding: '0.625rem 0.5rem',
    '& [class*="noWrap"]': {
      fontSize: 'inherit',
      fontWeight: 'inherit',
    },
  },
  head: {
    borderBottomColor: cssVar('--color-ebb'),
    color: cssVar('--color-mako'),
    fontSize: '0.75rem',
    fontWeight: 600,
    lineHeight: '1.3',
    '& > span': {
      display: 'flex',
      whiteSpace: 'nowrap',
    },
    '& button': {
      marginLeft: '0.3125rem',
      marginTop: '-0.125rem',
    },
    '& a': {
      cursor: 'pointer',
      textDecoration: 'none',
    },
  },
  body: {
    color: cssVar('--color-mako'),
    '&:first-of-type': {
      color: cssVar('--color-mine-shaft'),
      fontWeight: 600,
    },
    '& a': {
      cursor: 'pointer',
      textDecoration: 'none',
    },
    '& button': {
      '&:not(.MuiIconButton-root)': {
        marginTop: '-0.1875rem',
      },
      '&:not(:last-child)': {
        marginRight: '0.5rem',
      },
    },
    '& .flag': {
      display: 'inline-flex',
      marginRight: '0.3125rem',
      marginTop: '-0.125rem',
      verticalAlign: 'middle',
      '& img': {
        display: 'block',
      },
    },
  },
});
