import { cssVar } from 'utils/css';

export const getChipOverrides = () => ({
  root: {
    backgroundColor: cssVar('--color-wafer'),
    borderRadius: '0.75rem',
    color: cssVar('--color-mine-shaft'),
    height: '1.5rem',
    justifyContent: 'space-between',
    '&': {
      pointerEvents: 'none',
    },
    '&.is-active': {
      width: '100%',
    },
    '&.is-completed': {
      backgroundColor: cssVar('--color-pistachio'),
      color: cssVar('--color-white'),
    },
  },
  label: {
    fontSize: '0.75rem',
    fontWeight: 600,
    paddingLeft: '0.625rem',
    paddingRight: '0.625rem',
  },
  deleteIcon: {
    color: 'inherit',
    height: '1.25rem',
    margin: '0 0.3125rem 0 -0.3125rem',
    width: '1.25rem',
    '&:empty': {
      display: 'none',
    },
  },
});
