import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { getAppBarOverrides } from 'theme/overrides/app-bar';
import { getBackdropOverrides } from 'theme/overrides/backdrop';
import { getBaselineOverrides } from 'theme/overrides/baseline';
import { getButtonOverrides } from 'theme/overrides/button';
import { getCardOverrides } from 'theme/overrides/card';
import { getCardActionsOverrides } from 'theme/overrides/card-actions';
import { getCardContentOverrides } from 'theme/overrides/card-content';
import { getCardHeaderOverrides } from 'theme/overrides/card-header';
import { getCheckboxOverrides, getCheckboxSwitchOverrides } from 'theme/overrides/checkbox';
import { getChipOverrides } from 'theme/overrides/chip';
import { getCircularProgressOverrides } from 'theme/overrides/circular-progress';
import { getCollapseOverrides } from 'theme/overrides/collapse';
import { getContainerOverrides } from 'theme/overrides/container';
import { getDialogOverrides } from 'theme/overrides/dialog';
import { getDialogActionsOverrides } from 'theme/overrides/dialog-actions';
import { getDialogContentOverrides } from 'theme/overrides/dialog-content';
import { getDialogTitleOverrides } from 'theme/overrides/dialog-title';
import { getDrawerOverrides } from 'theme/overrides/drawer';
import { getFormControlOverrides } from 'theme/overrides/form-control';
import { getFormLabelOverrides } from 'theme/overrides/form-label';
import { getGridOverrides } from 'theme/overrides/grid';
import { getIconButtonOverrides } from 'theme/overrides/icon-button';
import { getInputAdornmentOverrides } from 'theme/overrides/input-adornment';
import { getInputBaseOverrides } from 'theme/overrides/input-base';
import { getInputLabelOverrides } from 'theme/overrides/input-label';
import { getLinearProgressOverrides } from 'theme/overrides/linear-progress';
import { getLinkOverrides } from 'theme/overrides/link';
import { getListOverrides } from 'theme/overrides/list';
import { getListItemOverrides } from 'theme/overrides/list-item';
import { getMenuOverrides } from 'theme/overrides/menu';
import { getMenuItemOverrides } from 'theme/overrides/menu-item';
import { getOutlinedInputOverrides } from 'theme/overrides/outlined-input';
import { getPaperOverrides } from 'theme/overrides/paper';
import { getPopoverOverrides } from 'theme/overrides/popover';
import { getRadioOverrides } from 'theme/overrides/radio';
import { getSelectOverrides } from 'theme/overrides/select';
import { getTabOverrides } from 'theme/overrides/tab';
import { getTableOverrides } from 'theme/overrides/table';
import { getTableCellOverrides } from 'theme/overrides/table-cell';
import { getTableContainerOverrides } from 'theme/overrides/table-container';
import { getTableRowOverrides } from 'theme/overrides/table-row';
import { getTabsOverrides } from 'theme/overrides/tabs';
import { getToolbarOverrides } from 'theme/overrides/toolbar';
import { getTooltipOverrides } from 'theme/overrides/tooltip';
import { cssVar } from 'utils/css';

export const onCreateTheme = () => {
  return createTheme(
    adaptV4Theme({
      props: {
        MuiButtonBase: {
          disableRipple: true,
        },
      },
      palette: {
        primary: {
          main: cssVar('--primary'),
        },
        text: {
          primary: cssVar('--color-mako'),
          secondary: '#b8b8b8',
        },
        background: {
          default: cssVar('--body-background'),
        },
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1300,
        },
      },
      typography: {
        fontFamily: ['"Montserrat"', 'sans-serif'].join(','),
        fontSize: 14,
        h1: {
          color: cssVar('--color-mine-shaft'),
          fontSize: 32,
          fontWeight: 700,
          lineHeight: '1.3',
        },
        h2: {
          color: cssVar('--color-mine-shaft'),
          fontSize: 22,
          fontWeight: 700,
          lineHeight: '1.3',
        },
        h3: {
          color: cssVar('--color-mine-shaft'),
          fontSize: 16,
          fontWeight: 700,
          lineHeight: '1.3',
        },
        h4: {
          color: cssVar('--color-mine-shaft'),
          fontSize: 16,
          fontWeight: 400,
          lineHeight: '1.3',
        },
        h5: {
          color: cssVar('--color-mine-shaft'),
          fontSize: 16,
          fontWeight: 700,
          lineHeight: '1.3',
        },
        h6: {
          color: cssVar('--color-mine-shaft'),
          fontSize: 18,
          fontWeight: 700,
          lineHeight: '1.3',
        },
        body1: {
          fontSize: 14,
          lineHeight: '1.6',
        },
        body2: {
          fontSize: 12,
          lineHeight: '1.6',
        },
        caption: {
          fontSize: 10,
          lineHeight: '1.6',
        },
        button: {
          textTransform: 'none',
        },
      },
      overrides: {
        MuiAppBar: {
          ...getAppBarOverrides(),
        },
        MuiCssBaseline: {
          ...getBaselineOverrides(),
        },
        MuiBackdrop: {
          ...getBackdropOverrides(),
        },
        MuiButton: {
          ...getButtonOverrides(),
        },
        MuiCard: {
          ...getCardOverrides(),
        },
        MuiCardActions: {
          ...getCardActionsOverrides(),
        },
        MuiCardContent: {
          ...getCardContentOverrides(),
        },
        MuiCardHeader: {
          ...getCardHeaderOverrides(),
        },
        MuiCheckbox: {
          ...getCheckboxOverrides(),
        },
        MuiChip: {
          ...getChipOverrides(),
        },
        MuiCircularProgress: {
          ...getCircularProgressOverrides(),
        },
        MuiCollapse: {
          ...getCollapseOverrides(),
        },
        MuiContainer: {
          ...getContainerOverrides(),
        },
        MuiDialog: {
          ...getDialogOverrides(),
        },
        MuiDialogActions: {
          ...getDialogActionsOverrides(),
        },
        MuiDialogContent: {
          ...getDialogContentOverrides(),
        },
        MuiDialogTitle: {
          ...getDialogTitleOverrides(),
        },
        MuiDrawer: {
          ...getDrawerOverrides(),
        },
        MuiFormControl: {
          ...getFormControlOverrides(),
        },
        MuiFormLabel: {
          ...getFormLabelOverrides(),
        },
        MuiGrid: {
          ...getGridOverrides(),
        },
        MuiIconButton: {
          ...getIconButtonOverrides(),
        },
        MuiInputAdornment: {
          ...getInputAdornmentOverrides(),
        },
        MuiInputBase: {
          ...getInputBaseOverrides(),
        },
        MuiInputLabel: {
          ...getInputLabelOverrides(),
        },
        MuiLinearProgress: {
          ...getLinearProgressOverrides,
        },
        MuiLink: {
          ...getLinkOverrides(),
        },
        MuiList: {
          ...getListOverrides(),
        },
        MuiListItem: {
          ...getListItemOverrides(),
        },
        MuiMenu: {
          ...getMenuOverrides(),
        },
        MuiMenuItem: {
          ...getMenuItemOverrides(),
        },
        MuiOutlinedInput: {
          ...getOutlinedInputOverrides(),
        },
        MuiPaper: {
          ...getPaperOverrides(),
        },
        MuiPopover: {
          ...getPopoverOverrides(),
        },
        MuiRadio: {
          ...getRadioOverrides(),
        },
        MuiSelect: {
          ...getSelectOverrides(),
        },
        MuiSwitch: {
          ...getCheckboxSwitchOverrides(),
        },
        MuiTab: {
          ...getTabOverrides(),
        },
        MuiTable: {
          ...getTableOverrides(),
        },
        MuiTableCell: {
          ...getTableCellOverrides(),
        },
        MuiTableContainer: {
          ...getTableContainerOverrides(),
        },
        MuiTableRow: {
          ...getTableRowOverrides(),
        },
        MuiTabs: {
          ...getTabsOverrides(),
        },
        MuiToolbar: {
          ...getToolbarOverrides(),
        },
        MuiTooltip: {
          ...getTooltipOverrides(),
        },
      },
    })
  );
};
