import { cssVar } from 'utils/css';

export const getTooltipOverrides = () => ({
  tooltip: {
    backgroundColor: cssVar('--color-white'),
    borderRadius: '0.25rem',
    boxShadow: cssVar('--shadow-card'),
    color: cssVar('--color-mine-shaft'),
    fontWeight: 600,
    maxWidth: 'none',
    padding: '0.25rem 0.5rem',
  },
  tooltipPlacementTop: {
    margin: '0 0 0.5rem 0 !important',
  },
  arrow: {
    color: cssVar('--color-white'),
  },
});
