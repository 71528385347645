import { useSnackbar as useSnackbarNotistack } from 'notistack';
import { useTranslation } from 'react-i18next';

export function useSnackbar() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbarNotistack();
  const { t } = useTranslation();

  type EnqueueArgs = Parameters<typeof enqueueSnackbar>;

  const enqueueSnackbarWithDefaults = (message: EnqueueArgs[0], options: EnqueueArgs[1] = {}) => {
    const defaultMessage = options.variant === 'error' && t('generic_error_message');

    return enqueueSnackbar(message || defaultMessage, {
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
      style: {
        paddingLeft: '50px',
      },
      autoHideDuration: options.persist ? undefined : 3000,
      ...options,
    });
  };

  return {
    enqueueSnackbar: enqueueSnackbarWithDefaults,
    closeSnackbar,
  };
}
