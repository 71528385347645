import { cssVar } from 'utils/css';

export const getTabOverrides = () => ({
  root: {
    fontWeight: 600,
    lineHeight: '1.6',
    maxWidth: 'none',
    minHeight: '0',
    minWidth: '0 !important',
    padding: '1rem 0.5rem',
    '&:not(:last-child)': {
      marginRight: '1rem',
      '@media (min-width: 60rem)': {
        marginRight: '1.25rem',
      },
    },
  },
  wrapper: {
    '& > span': {
      display: 'flex',
      minHeight: '6.25rem',
      '& > svg': {
        margin: 'auto',
      },
    },
  },
  labelIcon: {
    margin: '0 0.5rem',
    minHeight: '0',
    paddingTop: '0.5rem',
    '@media (min-width: 60rem)': {
      margin: '0 1.25rem',
    },
    '&:first-of-type': {
      marginLeft: '1rem',
      '@media (min-width: 60rem)': {
        marginLeft: '3rem',
      },
    },
    '&:not(:last-child)': {
      marginRight: '1.25rem',
      '@media (min-width: 60rem)': {
        marginRight: '2.5rem',
      },
    },
    '&:last-child': {
      marginRight: '1rem',
      '@media (min-width: 60rem)': {
        marginRight: '3rem',
      },
    },
    '& $wrapper': {
      '& > *:first-of-type': {
        marginBottom: '0',
      },
    },
  },
  textColorInherit: {
    color: cssVar('--color-mine-shaft'),
    opacity: '1',
    '&:hover': {
      color: cssVar('--primary-hover'),
    },
    '&:active': {
      color: cssVar('--primary-active'),
    },
    '&$selected': {
      color: cssVar('--primary'),
    },
  },
});
