import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Avatar,
  AppBar,
  Container,
  Drawer,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Toolbar,
  Typography,
  Collapse,
  Box,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBrowser,
  faCreditCard,
  faLifeRing,
  faPeopleArrows,
  faTachometerAltFast,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faAngleDown,
  faAngleUp,
  faBars,
  faTimes,
  faChevronDown,
} from '@fortawesome/pro-regular-svg-icons';
import { useProfile } from 'api/profile';
import useMediaQuery from '@mui/material/useMediaQuery';
import { cssVar } from 'utils/css';
import { getUsername, isAccountUser, isSiteAccess, useGetToken, useLogout } from 'utils/token';
import { linkHelper } from 'linkHelper';
import { useAuth0 } from '@auth0/auth0-react';
import { SHOW_NEW_DESIGN, UI_URL } from '../../constants';
import { useBillingSso } from 'api/auth';
import { useNewDesignSwitch } from 'component/hooks/useNewDesignSwitch';

const PREFIX = 'Header';

const classes = {
  logo: `${PREFIX}Logo`,
  list: `${PREFIX}List`,
  button: `${PREFIX}Button`,
  buttonToggle: `${PREFIX}ButtonToggle`,
  navigation: `${PREFIX}Navigation`,
  navigationItem: `${PREFIX}NavigationItem`,
  navigationItemActive: `${PREFIX}NavigationItemActive`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.logo}`]: {
    margin: '0 auto 0 0',
    padding: '0',
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      margin: '0 auto',
    },
    '& img': {
      display: 'block',
      height: 'auto',
      margin: '0',
      maxHeight: '1.875rem',
      maxWidth: '45vw',
      width: 'auto',
      [theme.breakpoints.up('md')]: {
        maxWidth: 'none',
      },
      [theme.breakpoints.up('lg')]: {
        maxHeight: '2.25rem',
      },
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  [`& .${classes.list}`]: {
    padding: '0.625rem 0',
    '& .MuiListItem-button': {
      backgroundColor: 'transparent',
      color: cssVar('--color-white'),
      textTransform: 'uppercase',
      transition: 'none',
      '&:hover': {
        backgroundColor: cssVar('--color-shark'),
        color: cssVar('--color-curious-blue'),
      },
      '& svg:first-of-type': {
        marginRight: '0.75rem',
      },
    },
    '& .Mui-selected': {
      backgroundColor: cssVar('--color-shark'),
      color: cssVar('--color-curious-blue'),
    },
    '& .MuiCollapse-root': {
      borderColor: cssVar('--color-curious-blue'),
      borderBottomWidth: '0',
      backgroundColor: cssVar('--color-shark'),
      '& .MuiList-root > *': {
        '&:not(:last-child)': {
          borderBottomWidth: '0',
        },
      },
      '& .MuiListItem-button': {
        textTransform: 'none',
      },
    },
    '& > *': {
      '&:not(:last-child)': {
        borderBottomWidth: 0,
      },
    },
  },

  [`& .${classes.button}`]: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& > *:first-of-type': {
      backgroundColor: cssVar('--color-blue-dianne'),
      color: cssVar('--color-regent-gray'),
      fontSize: '0.75rem',
      fontWeight: '400',
      height: '2rem',
      marginRight: '0.3125rem',
      width: '2rem',
    },
    '& > *:last-child': {
      color: cssVar('--color-curious-blue'),
      '& svg': {
        width: '0.75rem',
        height: '0.75rem',
      },
    },
  },

  [`& .${classes.buttonToggle}`]: {
    height: '2rem',
    width: '2rem',
    color: cssVar('--color-curious-blue'),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  [`& .${classes.navigation}`]: {
    display: 'none',
    margin: '0 auto',
    backgroundColor: cssVar('--color-shark'),
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
    '& ul': {
      display: 'flex',
    },
  },

  [`& .${classes.navigationItem}`]: {
    '&:not(:last-child)': {
      [theme.breakpoints.up('md')]: {
        marginRight: '0.5rem',
      },
      [theme.breakpoints.up('md')]: {
        marginRight: '2rem',
      },
    },
    '& > *': {
      alignItems: 'center',
      appearance: 'none',
      backgroundColor: 'transparent',
      border: '0',
      borderBottom: '0.1875rem solid transparent',
      borderRadius: '0',
      color: cssVar('--color-mine-shaft'),
      cursor: 'pointer',
      display: 'flex',
      fontSize: '0.875rem',
      fontWeight: 600,
      padding: '1.5rem 0.5rem 1.3125rem 0.5rem',
      textDecoration: 'none',
      textTransform: 'uppercase',
      [theme.breakpoints.up('md')]: {
        padding: '1.5rem 0.625rem 1.3125rem 0.625rem',
      },
      '&:hover': {
        color: cssVar('--primary'),
      },
      '& > *': {
        marginLeft: '0.3125rem',
      },
    },
  },

  [`& .${classes.navigationItemActive}`]: {
    '& a': {
      borderBottomColor: cssVar('--primary'),
    },
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  borderBottom: '0.0625rem solid var(--color-shark)',
  display: 'flex',
  flexShrink: '0',
  justifyContent: 'center',
  padding: '1.5rem 0',
}));

const DrawerFooter = styled('div')(({ theme }) => ({
  flexShrink: '0',
}));

const UserButton = styled('button')(({ theme }) => ({
  alignItems: 'center',
  appearance: 'none',
  backgroundColor: 'transparent',
  border: '0',
  borderRadius: '0',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '1rem 1.25rem',
  position: 'relative',
  width: '100%',
  '&::before': {
    backgroundColor: cssVar('--color-white'),
    content: '""',
    height: '0.0625rem',
    left: '0',
    position: 'absolute',
    right: '0',
    top: '0',
    opacity: '0.1',
  },
  '&:hover': {
    backgroundColor: cssVar('--color-shark'),
    color: cssVar('--color-curious-blue'),
  },
  '& > span': {
    alignItems: 'center',
    color: cssVar('--color-white'),
    display: 'flex',
    fontSize: '0.875rem',
    fontWeight: '600',
    '& > *': {
      backgroundColor: cssVar('--color-blue-dianne'),
      color: cssVar('--color-regent-gray'),
      fontSize: '0.75rem',
      fontWeight: '400',
      height: '2rem',
      marginRight: '0.625rem',
      width: '2rem',
    },
  },
  '& > div': {
    backgroundColor: cssVar('--color-shark'),
    borderRadius: cssVar('--border-radius'),
    color: cssVar('--color-curious-blue'),
    display: 'flex',
    height: '2rem',
    width: '2rem',
    '& > *': {
      margin: 'auto',
    },
  },
}));

interface Props {
  readonly hasData?: boolean;
  readonly drawerOpen: boolean;
  readonly onDrawerOpenToggle: () => unknown;
}

export function Header({ hasData, drawerOpen, onDrawerOpenToggle }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [sidebarBillingOpen, setSidebarBillingOpen] = React.useState(false);
  const open = Boolean(anchorEl);
  const token = useGetToken();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isLoading, data } = useProfile();
  const theme = useTheme();
  const isSmallBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  const { mutateAsync: getBillinglink } = useBillingSso();
  const accountUser = isAccountUser(token);
  const logout = useLogout();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { toggleNewDesign } = useNewDesignSwitch(false);

  const shouldShowMenu = hasData && token && !isSiteAccess(token);
  const drawerLinks = (
    <Root>
      <List className={classes.list}>
        <ListItem
          button
          component="a"
          onClick={() => navigate(linkHelper.dashboard.getLink())}
          selected={pathname.startsWith(linkHelper.dashboard.getLink())}
        >
          <FontAwesomeIcon icon={faTachometerAltFast} fixedWidth />
          <ListItemText primary={t('dashboard')} />
        </ListItem>
        <ListItem
          button
          component="a"
          onClick={() => navigate(linkHelper.sites.getLink())}
          selected={pathname.startsWith(linkHelper.sites.getLink())}
        >
          <FontAwesomeIcon icon={faBrowser} fixedWidth />
          <ListItemText primary={t('sites')} />
        </ListItem>
        {accountUser ? null : (
          <ListItem button onClick={() => setSidebarBillingOpen(v => !v)}>
            <FontAwesomeIcon icon={faCreditCard} fixedWidth />
            <ListItemText primary={t('billing')} />
            {sidebarBillingOpen ? (
              <FontAwesomeIcon icon={faAngleUp} />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} />
            )}
          </ListItem>
        )}
        {accountUser ? null : (
          <Collapse in={sidebarBillingOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={async () => {
                  const { data: linkData } = await getBillinglink({
                    destination: 'clientarea.php',
                    params: `action=productdetails&id=${data?.data.result?.package?.package_id}`,
                  });
                  window.location.href = linkData.result?.link ?? '';
                }}
              >
                <ListItemText primary={t('my_plan')} />
              </ListItem>
              <ListItem
                button
                onClick={async () => {
                  const { data } = await getBillinglink({
                    destination: 'clientarea.php',
                    params: 'action=invoices',
                  });
                  window.location.href = data.result?.link ?? '';
                }}
              >
                <ListItemText primary={t('invoices')} />
              </ListItem>
              <ListItem
                button
                onClick={async () => {
                  const { data } = await getBillinglink({
                    destination: 'index.php',
                    params: 'rp=/account/paymentmethods',
                  });
                  window.location.href = data.result?.link ?? '';
                }}
              >
                <ListItemText primary={t('payment_methods')} />
              </ListItem>
            </List>
          </Collapse>
        )}
        <ListItem button component="a" href="https://support.rocket.net" target="_blank">
          <FontAwesomeIcon icon={faLifeRing} fixedWidth />
          <ListItemText primary={t('support')} />
        </ListItem>
        <ListItem button component="a" href="https://rocket.net/affiliate-program" target="_blank">
          <FontAwesomeIcon icon={faPeopleArrows} fixedWidth />
          <ListItemText primary={t('affiliates')} />
        </ListItem>
      </List>
    </Root>
  );

  const userMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      id="user-menu"
      keepMounted
      onClose={handleClose}
      open={open}
    >
      <MenuItem>
        <Typography variant="body2">{getUsername(token)}</Typography>
      </MenuItem>
      {accountUser ? null : (
        <MenuItem
          onClick={async () => {
            const { data } = await getBillinglink({
              destination: 'feature-requests.php',
            });
            window.location.href = data.result?.link ?? '';
          }}
        >
          {t('feature_requests')}
        </MenuItem>
      )}
      {accountUser ? null : (
        <MenuItem
          onClick={async () => {
            const { data } = await getBillinglink({
              destination: 'clientarea.php',
              params: 'action=details',
            });
            window.location.href = data.result?.link ?? '';
          }}
        >
          {t('edit_account_details')}
        </MenuItem>
      )}
      {accountUser ? null : (
        <MenuItem
          onClick={async () => {
            const { data } = await getBillinglink({
              destination: 'index.php',
              params: 'rp=/account/paymentmethods',
            });
            window.location.href = data.result?.link ?? '';
          }}
        >
          {t('payment_methods')}
        </MenuItem>
      )}
      {accountUser ? null : (
        <MenuItem
          onClick={() => {
            handleClose();
            navigate(linkHelper.users.getLink());
          }}
        >
          {t('account_users')}
        </MenuItem>
      )}
      <MenuItem
        onClick={() => {
          handleClose();
          navigate(linkHelper.sites.templates.getLink());
        }}
      >
        {t('site_templates')}
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleClose();
          navigate(linkHelper.profile.changePassword.getLink());
        }}
      >
        {t('change_password')}
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleClose();
          navigate(linkHelper.profile.twoFactorAuthentication.getLink());
        }}
      >
        {t('security_settings')}
      </MenuItem>
      {accountUser ? null : (
        <MenuItem
          onClick={async () => {
            const { data } = await getBillinglink({
              destination: 'clientarea.php',
              params: 'action=emails',
            });
            window.location.href = data.result?.link ?? '';
          }}
        >
          {t('email_history')}
        </MenuItem>
      )}
      {SHOW_NEW_DESIGN ? (
        <MenuItem onClick={toggleNewDesign}>{t('switch_ui_to_modern_mode')}</MenuItem>
      ) : null}
      <MenuItem onClick={logout}>{t('logout')}</MenuItem>
    </Menu>
  );

  return (
    <Root>
      <AppBar position="relative" className={classes.navigation}>
        <Container maxWidth="lg">
          <Toolbar>
            <IconButton
              aria-label="logo"
              className={classes.logo}
              onClick={() => navigate(linkHelper.dashboard.getLink())}
            >
              <img src="/rocket.svg" alt="Rocket.net" />
            </IconButton>
            {!isLoading && (
              <IconButton
                aria-controls="user-menu"
                aria-haspopup="true"
                aria-label="Account of current user"
                onClick={handleMenu}
                className={classes.button}
              >
                <Avatar
                  alt={`${data?.data.result?.client?.firstname} ${data?.data.result?.client?.lastname}`}
                >
                  {data?.data.result?.client?.firstname?.charAt(0) ?? ''}
                  {data?.data.result?.client?.lastname?.charAt(0) ?? ''}
                </Avatar>
                <Box>
                  <FontAwesomeIcon icon={faChevronDown} />
                </Box>
              </IconButton>
            )}
            {userMenu}
            {shouldShowMenu ? (
              <IconButton
                id="sideBarMenu"
                onClick={onDrawerOpenToggle}
                className={classes.buttonToggle}
                size="large"
              >
                <FontAwesomeIcon icon={drawerOpen ? faTimes : faBars} />
              </IconButton>
            ) : null}
          </Toolbar>
        </Container>
      </AppBar>
      {/* mobile drawer */}
      {isSmallBreakpoint ? (
        <Drawer
          data-testid="appDrawer"
          anchor="top"
          elevation={0}
          open={drawerOpen}
          variant="temporary"
          sx={{
            zIndex: 1298,
            display: 'block',
            '& .MuiBackdrop-root': {
              backgroundColor: cssVar('--color-outer-space'),
            },
            '& .MuiDrawer-paper': {
              backgroundColor: cssVar('--color-outer-space'),
              '& > * > .MuiList-root': {
                boxShadow: 'inset 0 4px 4px rgba(0, 0, 0, 0.05)',
              },
            },
          }}
        >
          {drawerLinks}
        </Drawer>
      ) : (
        <Drawer
          sx={theme => ({
            display: 'block',
            [theme.breakpoints.up('md')]: {
              '&.MuiDrawer-docked': {
                display: 'flex',
                flex: '1 1 auto',
              },
            },
            '& .MuiDrawer-paper': {
              backgroundColor: cssVar('--color-outer-space'),
              borderRightWidth: '0',
              [theme.breakpoints.up('md')]: {
                width: '12rem',
                display: 'flex',
              },
              [theme.breakpoints.up('lg')]: {
                width: '17.5rem',
              },
              '& > *:nth-of-type(2)': {
                [theme.breakpoints.up('lg')]: {
                  flexGrow: '1',
                },
              },
            },
          })}
          variant="persistent"
          anchor="left"
          open
        >
          <DrawerHeader>
            <IconButton
              aria-label="logo"
              className={classes.logo}
              size="large"
              onClick={() => navigate(linkHelper.dashboard.getLink())}
            >
              <img src="/rocket.svg" alt="Rocket.net" />
            </IconButton>
          </DrawerHeader>
          {drawerLinks}
          <DrawerFooter>
            <Box sx={{ padding: 1, textAlign: 'center' }}>
              version: {import.meta.env.VITE_APP_VERSION}
            </Box>
            {!isLoading && (
              <UserButton
                aria-controls="user-menu"
                aria-haspopup="true"
                aria-label="Account of current user"
                onClick={handleMenu}
              >
                <span>
                  <Avatar
                    sx={{
                      display: 'inline-flex',
                      marginRight: '5px',
                    }}
                    alt={`${data?.data.result?.client?.firstname} ${data?.data.result?.client?.lastname}`}
                  >
                    {data?.data.result?.client?.firstname?.charAt(0) ?? ''}
                    {data?.data.result?.client?.lastname?.charAt(0) ?? ''}
                  </Avatar>
                  {data?.data.result?.client?.firstname}
                </span>
                <Box>
                  <FontAwesomeIcon icon={faChevronDown} />
                </Box>
              </UserButton>
            )}
            {userMenu}
          </DrawerFooter>
        </Drawer>
      )}
    </Root>
  );
}
